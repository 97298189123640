

.profile-slide{
    width: 100%;
    display: flex;
}
.profile-slide img{
    width: 80px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 1px solid grey;
}
.profile-slide .user-detail{
    margin-left: .5em;
}
.profile-slide .user-detail p{
    font-size: 20px;
    font-weight: 600;
}
.profile-slide .user-detail small{
    font-size: 14px;
    font-weight: 400;
}

#requestModal .modal-content{
    overflow-y: scroll;
}

#userProfile .profile-close {
    cursor: pointer;
}